import { useLocation } from '@reach/router'
import React from 'react'
import AddOwnershipPage from '../components/pages/AddOwnershipPage'
import Layout from '../layouts/layout'
import UnauthenticatedLayout from '../layouts/UnauthenticatedLayout'
import { CurrentUserState } from '../state/CurrentUserState'

const AddOwnership = () => {
  if (typeof window === 'undefined') return null
  const { isAuthenticated } = CurrentUserState.useContainer()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)

  if (!isAuthenticated) {
    return (
      <UnauthenticatedLayout>
        <AddOwnershipPage code={urlParams.get('code') || ''} />
      </UnauthenticatedLayout>
    )
  }
  return (
    <Layout>
      <AddOwnershipPage code={urlParams.get('code') || ''} />
    </Layout>
  )
}

export default AddOwnership
