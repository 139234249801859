import { useApolloClient } from '@apollo/client'
import { useLocation } from '@reach/router'
import Input from 'components/UI/Input'
import { navigate } from 'gatsby'
import useAmplitude from 'hooks/useAmplitude'
import { Button } from 'nzk-react-components'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CurrentUserState } from '../../../state/CurrentUserState'
import { IAccountOwnership } from '../StudentPage/AccountManagersSection/types.d'
import REGISTER_ACCOUNT_OWNERSHIP from './graphql/registerAccountOwnership.graphql'

interface IProps {
  code?: string
}

const Wrapper = styled.div`
  background-color: #1a0425;
  height: 100vh;
  padding-top: 100px;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`

const Title = styled.div`
  color: #fff;
  font-family: 'Rammetto One';
  margin-bottom: 20px;
  text-shadow: 0 3px 0 #afafaf, 0 6px 0 rgba(0, 0, 0, 0.4);
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  text-decoration: none !important;
  color: #fff !important;
  a {
    text-decoration: none !important;
    color: #fff !important;
  }
  > * {
    margin-right: 10px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Box = styled.div`
  background-color: #341644;
  max-width: 500px;
  margin: 0 auto;
  color: #fff;
  padding: 25px 0;
  border-radius: 20px;
  > * {
    margin-bottom: 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Form = styled.div`
  color: #fff;
  background-color: #ffffff11;
  padding: 20px 20px;
  font-family: 'Rammetto One';
  width: 100%;
  > * {
    margin-bottom: 13px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Field = styled.div`
  .error {
    color: #de1323;
    font-family: 'Libre Baskerville';
  }
  color: #222;
  > :first-child {
    color: #fff;
    position: relative;
    display: inline-flex;
  }
`

const ChildrenAdded = styled.div`
  font-family: 'Rammetto One';
  > * { margin-bottom: 8px; }
  > :last-child { margin-bottom: 0; }
`

const ErrorMessage = styled.div`
  padding: 0 15px;
  color: #de1323;
`

const AddOwnershipPage = (props: IProps) => {
  const { isAuthenticated } = CurrentUserState.useContainer()
  const [error, setError] = useState<string | null>(null)
  const [added, setAdded] = useState<IAccountOwnership[]>([])
  const client = useApolloClient()
  const location = useLocation()
  const codeRef = useRef<HTMLInputElement>()
  const usernameRef = useRef<HTMLInputElement>()
  const { logEvent } = useAmplitude()

  useEffect(() => {
    if (isAuthenticated) {
      logEvent('Dashboard: Add Followers - Username + PIN')
    }
  }, [isAuthenticated])

  const submit = async () => {
    if (!codeRef.current || !usernameRef.current) return
    try {
      const { data, errors } = await client.mutate({
        mutation: REGISTER_ACCOUNT_OWNERSHIP,
        variables: {
          code: codeRef.current.value,
          username: usernameRef.current.value.toLowerCase()
        },
        errorPolicy: 'all'
      })
      if (errors && errors[0].message === 'Not found.') {
        setError('We could not find an invite with that information. Please check and try again!')
        return
      }
      if (usernameRef.current && codeRef.current) {
        usernameRef.current.value = ''
        codeRef.current.value = ''
      }
      setAdded(added => ([...added, data.registerAccountOwnership]))
      logEvent('Dashboard: Add Followers - Username + PIN submitted')
    } catch (err) {
      console.log(err)
    }

  }

  if (isAuthenticated === false) {
    return (
      <Wrapper>
        <Content>
          <Title>Do you already have an account?</Title>
          <Actions>
              <Button size="regular" theme="confirm" onClick={() => {
                navigate(`/login?redirect=${location.pathname}${location.search}`)
              }}>
                Yes
              </Button>
              <Button size="regular" backgroundColor="#EC1A18" onClick={() => {
                navigate(`/register?redirect=${location.pathname}${location.search}`)
              }}>
                No
              </Button>
          </Actions>
        </Content>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Content>
        <Title>{ added.length > 0 ? 'Child Added' : 'Follow Child' }</Title>
        <Box>
          { error && <ErrorMessage>{error}</ErrorMessage> }
          {
            added.length > 0 && <><ChildrenAdded>
              {
                added.map(a => <div key={a._id}>{a.zookeeper.username} added</div>)
              }
            </ChildrenAdded>
            <Button size='regular' theme='confirm' onClick={() => {
              navigate(`/?successFollow=${added[0]._id}`)
            }}>Done</Button></>
          }
          <Form>
            <Field>
              <div>Username</div>
              { /* @ts-ignore */ }
              <Input ref={usernameRef} placeholder='e.g. fancytiger24' />
            </Field>
            <Field>
              <div>Code</div>
              { /* @ts-ignore */ }
              <Input ref={codeRef} placeholder='e.g. 5123' defaultValue={props.code} />
            </Field>
          </Form>
          <Button
            theme="purple"
            size="regular"
            disabled={false}
            onClick={submit}
          >
            Add Child
          </Button>
        </Box>
      </Content>
    </Wrapper>
  )
}

AddOwnershipPage.defaultProps = {
  code: null
}

export default AddOwnershipPage
